<template>
    <dashboard-layout>
        <div class="content-area__body">
            <!-- applicant details -->
            <section class="applicant-details">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="back-button">
                        <a href="#" @click.prevent="closeCandidateDetailsWindow()" class="back-button__icon">
                            <i class="eicon e-back"></i>
                        </a>
                        <div class="back-button__text">{{ $t("Back") }}</div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button v-if="showAssignAssessmentOption && canOrganizeCandidate" class="button semi-button-info mr-3"
                                @click="showAssessmentModal()">{{ $t(`Assign assessment`) }}
                        </button>
                        <button v-if="canDeleteCandidate" class="button semi-button-danger" @click="removeCandidate">{{ $t(`Remove`) }}</button>
                    </div>

                </div>
                <template v-if="candidate">
                    <div class="row">
                        <candidate-info-card :candidate="candidate" @emailCandidate="sendEmail()"></candidate-info-card>
                        <div class="col-xl-6 pr-0 order-2">
                            <div class="tab__card tab__card--primary gutter-10">
                                <a href="#" class="tab__control" v-for="(tab, index) in tabs"
                                   @click.prevent="changeTab(index)" :class="{'active' : tab.is_active}">
                                    <div class="tab__control__icon"><i :class="tab.icon"></i></div>
                                    <div class="tab__control__text">{{ $t(tab.title) }}</div>
                                </a>
                            </div>
                            <component :is="component" :candidate="candidate" :tabData="tabData"
                                       :key="reloadCount"></component>
                        </div>
                        <candidate-notes :candidate="candidate"></candidate-notes>
                    </div>
                </template>

            </section>
            <manage-assessment @showAssignedAssignment="showAssignedAssignment" v-if="manageAssessment" :selectedApplicants="selectedApplicants"
                               @closeModal="manageAssessment = false" :job="candidate.job_slug"
                               @assessmentUpdated="assessmentAdded"></manage-assessment>

        </div>
    </dashboard-layout>
</template>
<script>
import {SWITCH_TO_ACCOUNT} from "../../constants/action-type";

const TabApplication = () => import("../../components/candidate/details/TabApplication.vue");
const TabConversation = () => import('../../components/candidate/details/TabConversation.vue');
const TabEvaluation = () => import('../../components/candidate/details/TabEvaluation.vue');
const TabResume = () => import('../../components/candidate/details/TabResume.vue');
const CandidateInfoCard = () => import('../../components/candidate/details/CandidateInfoCard.vue');
const CandidateNotes = () => import('../../components/candidate/details/CandidateNotes.vue');
const ManageAssessment = () => import("../../components/modal/job/ManageAssessment");
const DashboardLayout = () => import('../../layouts/DashboardLayout');


import {mapActions, mapState} from 'vuex';
import {DEFAULT_PIPELINE, PERMISSIONS} from "../../constants/enums";
import client from "../../app/api/Client";
import {RELOAD_CANDIDATE_PROFILE, SWITCHED_TO_ANOTHER_ACCOUNT} from "../../constants/events";
import {EventBus} from "../../extra/event-bus";
import {getApplicantExists} from "../../app/api/CommonRequest";
import {canOrganizeCandidate, canDeleteCandidate} from "../../config/permission"

export default {
    components: {
        TabApplication,
        TabConversation,
        TabResume,
        TabEvaluation,
        CandidateInfoCard,
        CandidateNotes,
        DashboardLayout,
        ManageAssessment,
    },
    data() {
        return {
            back: '',
            tabs: [
                {
                    title: "Application",
                    is_active: true,
                    icon: 'eicon e-paper',
                    component: TabApplication
                },
                {
                    title: "Resume",
                    is_active: false,
                    icon: 'eicon e-cv',
                    component: TabResume
                },
                {
                    title: "Evaluation",
                    is_active: false,
                    icon: 'eicon e-contract',
                    component: TabEvaluation
                },
                {
                    title: "Conversation",
                    is_active: false,
                    icon: 'eicon e-chat',
                    component: TabConversation
                }
            ],
            component: TabApplication,
            candidate: null,
            tabData: null,
            manageAssessment: false,
            reloadCount: 0,
            logs: [],
            paginateData: []
        }
    },
    computed: {
        ...mapState(['company', 'user']),
        isEmpty() {
            return !this.logs.length;
        },
        selectedApplicants() {
            return [this.candidate.id];
        },
        jobId() {
            return this.candidate.job_id;
        },
        showAssignAssessmentOption() {
            if (this.candidate) {
                return ![
                    DEFAULT_PIPELINE.SELECTED,
                    DEFAULT_PIPELINE.REJECTED
                ].includes(this.candidate.pipeline_index);
            }
            return true;
        },
        canOrganizeCandidate() {
            return canOrganizeCandidate();
        },
        canDeleteCandidate() {
            return canDeleteCandidate();
        },
    },
    methods: {
        ...mapActions([SWITCH_TO_ACCOUNT]),
        async removeCandidate() {
            let confirm = await this.$dialog.confirm({
                title: this.$t(`Confirmation`),
                body: this.$t(`Are you sure, you want to remove this candidate's job application?`)
            });
            if (confirm) {
                try {
                    let {data: {data}} = await client().post(`/job/${this.candidate.job_slug}/candidate/delete`, {candidates: [this.$route.params.id]});
                    this.closeCandidateDetailsWindow()
                } catch (err) {
                } finally {
                    this.closeCandidateDetailsWindow()
                }
            }
        },
        showAssignedAssignment() {
            this.manageAssessment = false;
            this.reloadCount += 1;
            this.showEvaluation();
        },

        assessmentAdded() {
            this.$toast.success(this.$t(`Assessment assigned.`));
            this.manageAssessment = false;
            this.reloadCount += 1;
            this.showEvaluation();
        },
        showAssessmentModal() {
            getApplicantExists(this.candidate.id).then(() => {
            }).catch(() => {
            });
            this.manageAssessment = true;
        },
        changeTab(tabIndex) {
            this.tabs = this.tabs.map((tab, index) => {
                tab.is_active = false;
                if (index === tabIndex) {
                    tab.is_active = true;
                    this.component = tab.component;
                }
                return tab;
            })
        },
        hasPermission(tab) {
            if (tab.title === 'Conversation') {
                return this.company.package_rule.messaging;
            }
            if (tab.title === 'Evaluation') {
                if (this.company.package_rule.quiz || this.company.package_rule.screening) {
                    return true;
                }
                return false;
            }
            return true;
        },
        sendEmail() {
            this.changeTab(this.tabs.findIndex((t) => t.title === 'Conversation'));
            window.scrollTo(0, 0);
        },
        showEvaluation() {
            this.changeTab(this.tabs.findIndex((t) => t.title === 'Evaluation'));
            this.tabData = 3;
            window.scrollTo(0, 0);
        },
        showAttachment() {
            this.changeTab(this.tabs.findIndex((t) => t.title === 'Evaluation'));
            this.tabData = 4;
            window.scrollTo(0, 0);
        },
        showResume() {
            this.changeTab(this.tabs.findIndex((t) => t.title === 'Resume'));
            this.tabData = 4;
            window.scrollTo(0, 0);
        },
        async showCandidateDetails() {

            try {
                let {data: {data}} = await client().get(`/job/applicant/${this.$route.params.id}`);

                this.switchCompany(data.company_id);

                if (!this.company.permission[PERMISSIONS.CANDIDATE_VIEW]) {
                    this.$toast.error(this.$t('Sorry you do not have permission for this action.'));
                    await this.$router.push({name: 'dashboard'});
                }

                this.candidate = data;
                if (this.$route.query.tab == 'conversation') {
                    this.sendEmail();
                }
                if (this.$route.query.tab == 'evaluation-assessment') {
                    this.showEvaluation();
                }
                if (this.$route.query.tab === 'evaluation-attachment') {
                    this.showAttachment();
                }

                if (this.$route.query.tab === 'resume') {
                    this.showResume();
                }
            } catch (error) {
                this.$toast.error(this.$t(error.response.data.message));
                await this.$router.push({name: 'dashboard'});
            }
        },

        switchCompany(companyId) {
            if (companyId !== this.company.id) {
                let company = _.find(this.user.companies, {id: companyId});
                this[SWITCH_TO_ACCOUNT](company);
                EventBus.$emit(SWITCHED_TO_ANOTHER_ACCOUNT, 'company');
            }
        },

        closeCandidateDetailsWindow() {
            if (this.back.name && this.back.name.length>0 ) {
                return this.$router.push({name: this.back.name, params: this.back.params, query: this.back.query});
            } else {
                return  this.$router.push({name: 'jobs.published'});

            }
        }
    },
    beforeMount() {
        EventBus.$off(RELOAD_CANDIDATE_PROFILE);
    },
    async mounted() {
        window.scrollTo(0, 0);
        await this.showCandidateDetails();
        EventBus.$on(RELOAD_CANDIDATE_PROFILE, () => {
            this.showCandidateDetails();
        });

    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.back = from;
        });
    }
}
</script>

<style scoped>
.activity-body .note-info {
    padding-left: 0 !important;
}

.activity-body .note-info:before {
    display: none;
}

.activity-body .note-info-image {
    height: 30px;
    width: 30px;
    min-width: 30px;
    overflow: hidden;
    border-radius: 50px;
    margin-right: 10px;
}

.activity-body .note-time {
}

.activity-body .note-time span {
    color: #7d8091 !important;
    font-size: 12px;
}

.activity-body .label__full--warning {
    background-color: #f8dbc750;
    border: 1px solid rgba(255, 150, 53, 0.5) !important;
}

.activity-body .label__full--success {
    background-color: #cbebd950;
    border: 1px solid rgba(96, 206, 131, 0.5) !important;
}

.activity-body .label__full--danger {
    background-color: #f8ccd650;
    border: 1px solid rgba(248 204 214, 0.5) !important;
}

.log-image {
    height: 30px;
    width: 30px;
    min-width: 30px;
    overflow: hidden;
    border-radius: 50px;
    margin-right: 10px;
}

.log-label {
    padding: 2px 10px;
    margin-bottom: 5px;
    border-radius: 30px;
}

code {
    padding: 3px 5px;
    font-size: 12px;
    border-radius: 20px;
    margin: 6px 0;
    display: inline-block;
    background: rgba(89, 125, 252, 0.15);
    color: #597dfc;
}
@media all and (max-width: 767px) {
    .applicant-details .back-button .back-button__text {
        display: none;
    }
}
</style>
